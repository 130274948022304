import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Thumbnail from 'components/ui/Thumbnail';
import { findEmployee } from 'data/utils/employee';
import { useBrand } from 'hooks/useBrand';
import { useTranslation } from 'hooks/useTranslation';
import styles from './PatientManagerCard.module.scss';
import type { Employee } from 'types/Employee';

type Props = {
  managerId?: string;
  className?: string;
};

const PatientManagerCard = ({
  managerId,
  className,
}: Props): JSX.Element | null => {
  const { t, locale } = useTranslation(true);
  const { brand } = useBrand();

  const [manager, setManager] = useState<Employee | null>(null);

  useEffect(() => {
    if (!locale || !managerId) {
      return;
    }

    const run = async (): Promise<void> => {
      const manager = await findEmployee(locale, managerId);
      setManager(manager);
    };

    run();
  }, [locale, managerId]);

  const { defaultManager } = brand || {};

  if (!manager && !defaultManager) {
    return null;
  }

  const { firstname, lastname, profileImage } = manager || defaultManager || {};
  const name = manager ? [firstname, lastname].join(' ') : firstname;

  return (
    <div className={clsx(styles.card, className)}>
      <Thumbnail
        src={profileImage?.url || ''}
        alt={profileImage?.description || 'patient care manager'}
        size={48}
        shape="circle"
      />
      <div>
        <p className={styles.name}>{name}</p>
        <p className={styles.description}>
          {t('home.yourPersonalPatientsCareManager')}
        </p>
      </div>
    </div>
  );
};

export default PatientManagerCard;
