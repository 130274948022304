import { FunnelId } from 'constants/funnel';
import clsx from 'clsx';
import Button from 'components/Qds/Button';
import ExternalFunnelButton from 'components/Shared/ExternalFunnel/ExternalFunnelButton';
import PatientManagerCard from 'components/Shared/PatientManagerCard';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { generateScopedTransitionClasses } from 'utils/transitions';
import { Direction } from 'types/Utilities';
import styles from './ConversionBar.module.scss';
import type { PassiveEventHandler } from 'types/Utilities';
import type { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  hideContactButton?: boolean;
  hideGetAQuoteButton?: boolean;
  yPageOffset?: number;
};

const ConversionBar = ({
  children,
  hideContactButton,
  hideGetAQuoteButton,
  yPageOffset = 300,
}: Props): JSX.Element | null => {
  const { locale, t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const [isVisible, setIsVisible] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const scrollToReveal = (): void => {
    if (window.pageYOffset >= yPageOffset && !isVisible) {
      setIsVisible(true);
      document.body.classList.add('conversion-bar-visible');
    }
  };

  useEffect(() => {
    (window.addEventListener as PassiveEventHandler)('scroll', scrollToReveal, {
      passive: true,
    });

    return () => {
      (window.removeEventListener as PassiveEventHandler)(
        'scroll',
        scrollToReveal,
        {
          passive: true,
        },
      );
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      document.body.classList.remove('conversion-bar-visible');
    }

    return () => {
      (window.removeEventListener as PassiveEventHandler)(
        'scroll',
        scrollToReveal,
        {
          passive: true,
        },
      );
    };
  }, [isVisible]);

  const overlayTransitionClasses = generateScopedTransitionClasses(
    'fade',
    styles,
  );

  return (
    <CSSTransition
      in={isVisible}
      classNames={overlayTransitionClasses}
      mountOnEnter
      unmountOnExit
      onExiting={() => {
        setShowContent(false);
      }}
      onEntered={() => {
        setShowContent(true);
      }}
      timeout={{
        exit: 500,
      }}
    >
      <>
        <div
          className={clsx(
            styles.conversionBar,
            'conversion-bar-container',
            showContent && 'conversion-bar-open',
          )}
        >
          <div className={styles.container}>
            {!hideContactButton && (
              <Link
                href={`/${locale}/${t('footer.contactUs.link')}`}
                title={t('footer.contactUs')}
                passHref
              >
                <Button
                  className={styles.contactButton}
                  variant="tertiary"
                  ghost
                  fullWidth
                  isLink
                  onClick={() => {
                    trackEvent({
                      category: 'CTA',
                      action: 'CTA|Click',
                      label: 'CTA|Stickybar|ContactUs',
                    });
                  }}
                >
                  {t('footer.contactUs')}
                </Button>
              </Link>
            )}
            {children ? (
              children
            ) : (
              <div
                className={clsx(!hideContactButton && styles.patientManager)}
              >
                <PatientManagerCard />
              </div>
            )}
            {!hideGetAQuoteButton && (
              <div className={styles.getAQuote}>
                <ExternalFunnelButton
                  funnelId={FunnelId.CONTACT_FORM}
                  renderButton={(openFunnel) => {
                    return (
                      <Button
                        size="large"
                        shadow={false}
                        fullWidth
                        icon={<Icon type="Arrow" direction={Direction.RIGHT} />}
                        onClick={() => {
                          trackEvent({
                            category: 'CTA',
                            action: 'CTA|Click',
                            label: 'CTA|Stickybar|GetaQuote',
                          });

                          openFunnel();
                        }}
                      >
                        <span className="md:px-12">{t('getAQuote')}</span>
                      </Button>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </>
    </CSSTransition>
  );
};

export default ConversionBar;
