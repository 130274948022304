import { isString } from './string';

export const formatNumber = (
  value: number | string,
  locale: string,
): string => {
  let numberLocale = locale;
  if (
    numberLocale === 'es' ||
    numberLocale === 'it' ||
    numberLocale === 'nl' ||
    numberLocale === 'fr'
  ) {
    numberLocale = 'de';
  }

  return new Intl.NumberFormat(numberLocale, {}).format(
    isString(value) ? parseFloat(value) : value,
  );
};

/**
 * Checks whether the value passed as an argument is a valid, finite number.
 * This is a polyfill to [Number.isFinite](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isFinite#Polyfill)
 * which is not available on IE
 *
 * @param potentialNumber - argument to be checked for being an finite number
 * @returns whether or not the argument is a finite number
 */
export const isFiniteNumber = (potentialNumber: unknown): boolean => {
  return typeof potentialNumber === 'number' && isFinite(potentialNumber);
};
